import React, { useEffect, useRef, useState } from "react";
import { Container, Row, Col, Collapse } from "react-bootstrap";

import "bootstrap/dist/css/bootstrap.min.css";

import "../styles/style.css";
const ContactPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* SECTION 1 */}
      <section className="text-center section-contactwrite-page">
        <Container>
          <div className="contactwrite-box">
            <h2 className="cst-title-2 bold">어떤 개발이 필요하신가요?</h2>
            <h2 className="cst-title-sub-2 cst-grey">
              부담없이 예상견적과 답변을 받아보세요
            </h2>
            <div className="contactwrite-example">
              <h2>
                예시를 참고해 자세히 문의해주시면 더 정확히 답변드릴 수 있습니다
              </h2>
              <div className="contactwrite-example-box">
                <h3>개발이 필요한 범위</h3>
                <p>예시: 개발 / 기획+디자인+개발</p>
                <h3>프로젝트의 진행상황</h3>
                <p>
                  예시: 새로운 프로젝트입니다 / 현재 운영중인 서비스의
                  유지보수가 필요한 상황입니다 / 전임자가 개발한 내용이 남아있는
                  중단상황입니다 / 사내 프론트개발자가 페이지는 완성한
                  상황입니다
                </p>
                <h3>기획 상황</h3>
                <p>
                  예시: 아이디어만 있습니다 / 필수적인 내용이 간략히
                  정리되어있습니다 / 상세히 정리해 문서화되어 있습니다
                </p>
                <h3>상세 설명</h3>
                <p>
                  예시:<br></br>
                  사내 포인트로 상품을 결제하는 사이트를 개발하려고 합니다.
                  <br></br>
                  로그인, 포인트 결제, 잔액 현금 결제 기능이 필요합니다.
                  <br></br>
                  디자인은 기존 회사사이트(www.example.co.kr)의 디자인과 비슷한
                  디자인으로 심플하게 진행했으면 좋겠고,<br></br>
                  www.example2.co.kr사이트의 결제방식과 동일한 방식으로 동작하길
                  원해 참고바랍니다.
                  <br></br>/ 서면으로 설명이 어려워 전화로 설명이
                  필요합니다(번호 등 연락처 기입)
                </p>
                <h3>예상 진행기간</h3>
                <p>
                  예시: 다음달 말까지 최종 검수가 마무리되어야 합니다 /
                  다음주까지 기본적인 틀을 보기를 원합니다 / *개월
                </p>
                <h3>예상 시작일</h3>
                <p>예시: 계약성사후 바로 / *월초부터</p>
                <h3>예상 예산범위</h3>
                <p>예시: *만원~*만원 예상 / 상담 후 결정 원함</p>
              </div>
            </div>
            <a href="mailto:adamkim@techfree.kr">
              <button className="cst-primary-btn">문의하기</button>
            </a>
          </div>
        </Container>
      </section>
    </>
  );
};

export default ContactPage;
