import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { Helmet } from "react-helmet";

import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col, Collapse } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Typewriter } from "react-simple-typewriter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

import "../styles/style.css";

// 이미지
import logo from "../assets/textlogo_bl.svg";

import imgFigure01 from "../assets/img_figure_01.png";
import imgFigure02 from "../assets/img_figure_02.png";
import imgFigure03 from "../assets/img_figure_03.png";
import imgDevSample01 from "../assets/portfolio/atisian_1.jpg";
import imgDevSample05 from "../assets/portfolio/pdfsellerapp_1.jpg";
import imgDevSample06 from "../assets/portfolio/nh_1.jpg";
import imgDevSample07 from "../assets/portfolio/oreumi_1.jpg";
import imgDevSample08 from "../assets/portfolio/ocrpdf_1.jpg";
import imgDevSample09 from "../assets/portfolio/colorreader_2.jpg";
import imgDevSample10 from "../assets/portfolio/wordpress_1.jpg";

// 스택 이미지
import {
  stackapp1,
  stackapp2,
  stackback1,
  stackback2,
  stackback3,
  stackback4,
  stackback5,
  stackback6,
  stackback7,
  stackback8,
  stackback9,
  stackcloud1,
  stackcloud2,
  stackcloud3,
  stackcloud4,
  stackdb1,
  stackdb2,
  stackdb3,
  stackdb4,
  stackdb5,
  stackdb6,
  stackdb7,
  stackdb8,
  stackdesign1,
  stackdesign2,
  stackdesign3,
  stackdesign4,
  stackdesign5,
  stackdesign6,
  stackfront1,
  stackfront2,
  stackfront3,
  stackfront4,
  stackfront5,
  stackfront6,
  stackfront7,
  stackos1,
  stackos2,
  stackos3,
  stackos4,
  stackos5,
  stacktool1,
  stacktool2,
  stacktool3,
  stackai1,
  stackai2,
  stackai3,
  stackai4,
  stackai5,
  stackai6,
  stackai7,
} from "../assets/stackImgs";

// 포트폴리오부분 데이터
import portfolioItems from "./portfolioData";

const HomePage = () => {
  // 이 페이지 접속시 첫부분으로 스크롤함
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // 섹션마다 페이드인/아웃 효과 적용
  const sectionRef2 = useRef(null);
  const sectionRef3 = useRef(null);
  const sectionRef4 = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          } else {
            entry.target.classList.remove("visible");
          }
        });
      },
      { threshold: 0.15 } // 15% 요소가 보이면 트리거
    );

    if (sectionRef2.current) {
      observer.observe(sectionRef2.current);
    }
    if (sectionRef3.current) {
      observer.observe(sectionRef3.current);
    }
    if (sectionRef4.current) {
      observer.observe(sectionRef4.current);
    }

    return () => {
      if (sectionRef2.current) {
        observer.unobserve(sectionRef2.current);
      }
      if (sectionRef3.current) {
        observer.unobserve(sectionRef3.current);
      }
      if (sectionRef4.current) {
        observer.unobserve(sectionRef4.current);
      }
    };
  }, []);

  // 주소에 특정 id있을 시 스크롤
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.hash) {
      const id = location.hash.replace("#", "");
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView();
      }
    }
  }, [location]);

  // 포트폴리오
  const allItems = Object.values(portfolioItems).flat();

  // 타이틀을 기준으로 중복요소 제거
  const uniqueItems = allItems.filter(
    ((usedTitles) => (item) => {
      if (usedTitles.has(item.title)) {
        return false;
      }
      usedTitles.add(item.title);
      return true;
    })(new Set())
  );
  const sortedItems = uniqueItems.sort((a, b) => b.id - a.id);

  // 포트폴리오 모달
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const openModal = (item) => {
    setSelectedItem(item);
    setShowModal(true);
  };

  return (
    <>
      <Helmet>
        <title>테크프리</title>
        <meta
          name="description"
          content="테크프리는 최신 기술을 활용해 맞춤형 소프트웨어 개발, 전문 컨설팅, 효율적인 프로젝트 관리를 제공합니다."
        />
        <meta property="og:title" content="테크프리:개발의 모든것" />
        <meta
          property="og:description"
          content="테크프리는 최신 기술을 활용해 맞춤형 소프트웨어 개발부터 전문 컨설팅까지 제공합니다."
        />
        <meta property="og:image" content={logo} />
        <meta property="og:url" content="https://www.techfree.kr" />
        <meta property="og:type" content="website" />
        <meta name="twitter:title" content="테크프리:개발의 모든것" />
        <meta
          name="twitter:description"
          content="테크프리는 최신 기술을 활용해 맞춤형 소프트웨어 개발부터 전문 컨설팅까지 제공합니다."
        />
        <meta name="twitter:image" content={logo} />
      </Helmet>

      {/* 포트폴리오 MODAL */}
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        className="modal-custom"
      >
        <Modal.Header closeButton>
          <Modal.Title>{selectedItem?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body modal-body-scroll">
          {selectedItem?.description.split("\n").map((line, lineIndex) => (
            <p key={lineIndex}>{line}</p>
          ))}

          {selectedItem?.images.map((image, index) => (
            <div key={index} className="modal-body-img">
              <img
                src={image}
                alt={`${selectedItem?.title} - 이미지 ${index}`}
              />
            </div>
          ))}
        </Modal.Body>
      </Modal>
      {/* SECTION 1 */}
      <section className="text-center section-title">
        <Container>
          <div className="py-4">
            <div className="py-2">
              <h1 className="cst-title-1 bold cst-blue">
                {" "}
                <span className="typewriter-text">
                  <Typewriter
                    words={[
                      "웹페이지",
                      "모바일앱",
                      "플랫폼",
                      "소프트웨어",
                      "무엇이든",
                    ]}
                    loop={true}
                    cursor
                    cursorStyle="|"
                    typeSpeed={70}
                    deleteSpeed={50}
                    delaySpeed={2000}
                  />
                </span>
              </h1>
              <h1 className="cst-title-1 bold">개발해드립니다</h1>
            </div>
            <p className="cst-title-sub">
              구현 하고싶은 아이디어, 테크프리에 맡기세요
            </p>
          </div>
          <Link to="/contact">
            <button className="cst-primary-btn cst-btn-big blue-shadow">
              견적 확인하기
            </button>
          </Link>
        </Container>
      </section>
      {/* SECTION 2 */}
      <section
        ref={sectionRef2}
        className="text-center cst-back-dark section-service fade-in-section"
      >
        <Container>
          <div className="py-4">
            <h2 className="cst-title-2">디자인부터 개발, 유지보수까지</h2>
            <h2 className="cst-title-2 gradient-text bold">
              한번에 해결하세요!
            </h2>
          </div>
          <Row className="justify-content-md-center gap-4">
            <Col md={3} className="mb-4 d-flex flex-column section-service-box">
              <div className="mb-2">
                <img src={imgFigure01} />
              </div>
              <h5 className="bold">디자인도 믿고맡기세요</h5>
              <p>
                웹/앱/프로그램 등 다양한 분야를 직접 진행한 디자이너가<br></br>{" "}
                <span className="highlight-blue">감각적인 디자인 서비스</span>를
                제공합니다.
              </p>
            </Col>
            <Col md={3} className="mb-4 d-flex flex-column section-service-box">
              <div className="mb-2">
                <img src={imgFigure02} />
              </div>
              <h5 className="bold">실력있는 전문 개발팀</h5>
              <p>
                <span className="highlight-blue">
                  IT교육 경력이 있는 지식과 경력
                </span>
                을 갖춘 고급 개발자들이<br></br> 직접 구축해 믿을 수 있습니다.
              </p>
            </Col>
            <Col
              md={3}
              className="mb-4 d-flex flex-column section-service-box-blue"
            >
              <div className="mb-2">
                <img src={imgFigure03} />
              </div>
              <h5 className="bold">구축 그 이후도 편하게</h5>
              <p>
                개발 노하우를 살려{" "}
                <span className="highlight-darkblue">
                  이후의 실무자도 일하기 편하게
                </span>{" "}
                개발해드립니다.
                <br></br> 사후 관리도 깔끔히 진행합니다.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      {/* SECTION 3 */}
      <section ref={sectionRef3} className="text-center fade-in-section">
        <Container fluid>
          {" "}
          <Row
            className="justify-content-md-center d-flex section-type-box"
            onClick={() => navigate("/web")}
          >
            <Col xs={6} className=" section-type-img-box">
              {" "}
              <img
                src={imgDevSample01}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  objectPosition: "center",
                }}
              />
            </Col>
            <Col
              xs={6}
              className="d-flex align-items-center section-type-text-box"
            >
              {" "}
              <div style={{ textAlign: "left" }}>
                <h5 className="bold">웹페이지 개발</h5>
                <p>
                  기획 / 디자인<br></br>퍼블리싱<br></br>반응형 웹페이지 개발
                  <br></br>SEO 최적화
                </p>
                <button>
                  더보기 <FontAwesomeIcon icon={faArrowRight} />
                </button>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-md-center d-flex section-type-box">
            <Col
              xs={6}
              className=" section-type-img-box"
              onClick={() => navigate("/mobile")}
            >
              {" "}
              <img
                src={imgDevSample05}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  objectPosition: "center",
                }}
              />
            </Col>
            <Col
              xs={6}
              className="d-flex align-items-center section-type-text-box"
            >
              {" "}
              <div style={{ textAlign: "left" }}>
                <h5 className="bold">모바일 앱개발</h5>
                <p>
                  네이티브앱 (안드로이드 / IOS)<br></br>
                  하이브리드 앱<br></br>
                  웹앱<br></br>
                  프로그레시브 앱<br></br>
                </p>
                <button>
                  더보기 <FontAwesomeIcon icon={faArrowRight} />
                </button>
              </div>
            </Col>
          </Row>
          <Row
            className="justify-content-md-center d-flex section-type-box"
            onClick={() => navigate("/platform")}
          >
            <Col xs={6} className=" section-type-img-box">
              {" "}
              <img
                src={imgDevSample09}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  objectPosition: "center",
                }}
              />
            </Col>
            <Col
              xs={6}
              className="d-flex align-items-center section-type-text-box"
            >
              {" "}
              <div style={{ textAlign: "left" }}>
                <h5 className="bold">플랫폼 개발</h5>
                <p>
                  예약 / 매칭 / 상담 서비스<br></br>
                  쇼핑몰 / 자사몰<br></br>
                  의료진단결과 / 소프트웨어 출력/ 엑셀 / 데이터베이스 문서화
                  <br></br>
                  IoT, 라즈베리파이, 포스기, 안내로봇
                </p>
                <button>
                  더보기 <FontAwesomeIcon icon={faArrowRight} />
                </button>
              </div>
            </Col>
          </Row>
          <Row
            className="justify-content-md-center d-flex section-type-box"
            onClick={() => navigate("/software")}
          >
            <Col xs={6} className=" section-type-img-box">
              {" "}
              <img
                src={imgDevSample06}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  objectPosition: "center",
                }}
              />
            </Col>
            <Col
              xs={6}
              className="d-flex align-items-center section-type-text-box"
            >
              {" "}
              <div style={{ textAlign: "left" }}>
                <h5 className="bold">소프트웨어 개발</h5>
                <p>
                  운영체제 맟춤형 프로그램 개발<br></br>
                  크롤링 / 자동화 프로그램
                </p>
                <button>
                  더보기 <FontAwesomeIcon icon={faArrowRight} />
                </button>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-md-center d-flex section-type-box">
            <Col xs={6} className=" section-type-img-box">
              {" "}
              <img
                src={imgDevSample10}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  objectPosition: "center",
                }}
              />
            </Col>
            <Col
              xs={6}
              className="d-flex align-items-center section-type-text-box"
            >
              {" "}
              <div style={{ textAlign: "left" }}>
                <h5 className="bold">개발 운영</h5>
                <p>
                  Cafe24 / Wordpress / Webflow 지원<br></br>
                  클라우드 서비스 / DevOps<br></br>
                  운영 배포 지원 서비스<br></br>
                  서버 설정 <br></br>
                  데이터 마이닝 / 데이터 센터 구축<br></br>
                  마이그레이션<br></br>
                </p>
                {/* <button>
                  더보기 <FontAwesomeIcon icon={faArrowRight} />
                </button> */}
              </div>
            </Col>
          </Row>
          <Row
            className="justify-content-md-center d-flex section-type-box"
            onClick={() => navigate("/research")}
          >
            <Col xs={6} className=" section-type-img-box">
              {" "}
              <img
                src={imgDevSample08}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  objectPosition: "center",
                }}
              />
            </Col>
            <Col
              xs={6}
              className="d-flex align-items-center section-type-text-box"
            >
              {" "}
              <div style={{ textAlign: "left" }}>
                <h5 className="bold">전문 연구 분야</h5>
                <p>
                  AI 음성, 이미지 처리<br></br>
                  OCR 광학 문자 인식
                </p>
                <button>
                  더보기 <FontAwesomeIcon icon={faArrowRight} />
                </button>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-md-center d-flex section-type-box">
            <Col xs={6} className=" section-type-img-box">
              {" "}
              <img
                src={imgDevSample07}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  objectPosition: "center",
                }}
              />
            </Col>
            <Col
              xs={6}
              className="d-flex align-items-center section-type-text-box"
            >
              {" "}
              <div style={{ textAlign: "left" }}>
                <h5 className="bold">IT 교육</h5>
                <p>비대면 화상강의 강사/멘토링 경력자 보유</p>
                {/* <button>
                  더보기 <FontAwesomeIcon icon={faArrowRight} />
                </button> */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* SECTION 4 */}
      <section
        ref={sectionRef4}
        className="text-center py-4 grey fade-in-section"
      >
        <Container>
          <div className="py-4">
            <h2 className="cst-title-2 bold">최고의 기술로</h2>
            <h2 className="cst-title-2 bold">
              <span className="gradient-text-dark">최적의 솔루션</span>을
              제공합니다
            </h2>
          </div>
          <div className="section-stack-box">
            <Row className="justify-content-md-center align-items-center">
              <Col xs={12} className="d-flex justify-content-center">
                {" "}
                <div className="section-stack-text-box">CLOUD</div>
              </Col>
            </Row>
            <Row className="justify-content-md-center align-items-center">
              <Col md="auto">
                <Row className="justify-content-center">
                  <Col xs={3} sm={2} md={2} className="stack-box">
                    <img src={stackcloud1} />
                  </Col>
                  <Col xs={3} sm={2} md={2} className="stack-box">
                    <img src={stackcloud2} />
                  </Col>
                  <Col xs={3} sm={2} md={2} className="stack-box">
                    <img src={stackcloud3} />
                  </Col>
                  <Col xs={3} sm={2} md={2} className="stack-box">
                    <img src={stackcloud4} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <div className="section-stack-box">
            <Row className="justify-content-md-center align-items-center">
              <Col xs={12} className="d-flex justify-content-center">
                {" "}
                <div className="section-stack-text-box">OS / SERVER</div>
              </Col>
            </Row>
            <Row className="justify-content-md-center align-items-center">
              <Col md="auto">
                <Row className="justify-content-center">
                  <Col xs={3} sm={2} md={2} className="stack-box">
                    <img src={stackos1} />
                  </Col>
                  <Col xs={3} sm={2} md={2} className="stack-box">
                    <img src={stackos2} />
                  </Col>
                  <Col xs={3} sm={2} md={2} className="stack-box">
                    <img src={stackos3} />
                  </Col>
                  <Col xs={3} sm={2} md={2} className="stack-box">
                    <img src={stackos4} />
                  </Col>
                  <Col xs={3} sm={2} md={2} className="stack-box">
                    <img src={stackos5} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <div className="section-stack-box">
            <Row className="justify-content-md-center align-items-center">
              <Col xs={12} className="d-flex justify-content-center">
                {" "}
                <div className="section-stack-text-box">BACKEND</div>
              </Col>
            </Row>
            <Row className="justify-content-md-center align-items-center">
              <Col md="auto">
                <Row className="justify-content-center">
                  <Col xs={3} sm={2} md={2} className="stack-box">
                    <img src={stackback1} />
                  </Col>
                  <Col xs={3} sm={2} md={2} className="stack-box">
                    <img src={stackback2} />
                  </Col>
                  <Col xs={3} sm={2} md={2} className="stack-box">
                    <img src={stackback3} />
                  </Col>
                  <Col xs={3} sm={2} md={2} className="stack-box">
                    <img src={stackback4} />
                  </Col>
                  <Col xs={3} sm={2} md={2} className="stack-box">
                    <img src={stackback5} />
                  </Col>
                </Row>
                <Row className="justify-content-center">
                  <Col xs={3} sm={2} md={2} className="stack-box">
                    <img src={stackback6} />
                  </Col>
                  <Col xs={3} sm={2} md={2} className="stack-box">
                    <img src={stackback7} />
                  </Col>
                  <Col xs={3} sm={2} md={2} className="stack-box">
                    <img src={stackback8} />
                  </Col>
                  <Col xs={3} sm={2} md={2} className="stack-box">
                    <img src={stackback9} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <div className="section-stack-box">
            <Row className="justify-content-md-center align-items-center">
              <Col xs={12} className="d-flex justify-content-center">
                {" "}
                <div className="section-stack-text-box">FRONTEND</div>
              </Col>
            </Row>
            <Row className="justify-content-md-center align-items-center">
              <Col md="auto">
                <Row className="justify-content-center">
                  <Col xs={3} sm={2} md={2} className="stack-box">
                    <img src={stackfront1} />
                  </Col>
                  <Col xs={3} sm={2} md={2} className="stack-box">
                    <img src={stackfront2} />
                  </Col>
                  <Col xs={3} sm={2} md={2} className="stack-box">
                    <img src={stackfront3} />
                  </Col>
                  <Col xs={3} sm={2} md={2} className="stack-box">
                    <img src={stackfront4} />
                  </Col>
                  <Col xs={3} sm={2} md={2} className="stack-box">
                    <img src={stackfront5} />
                  </Col>
                </Row>
                <Row className="justify-content-center">
                  <Col xs={3} sm={2} md={2} className="stack-box">
                    <img src={stackfront6} />
                  </Col>
                  <Col xs={3} sm={2} md={2} className="stack-box">
                    <img src={stackfront7} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <div className="section-stack-box">
            <Row className="justify-content-md-center align-items-center">
              <Col xs={12} className="d-flex justify-content-center">
                {" "}
                <div className="section-stack-text-box">DATABASE</div>
              </Col>
            </Row>
            <Row className="justify-content-md-center align-items-center">
              <Col md="auto">
                <Row className="justify-content-center">
                  <Col xs={3} sm={2} md={2} className="stack-box">
                    <img src={stackdb1} />
                  </Col>
                  <Col xs={3} sm={2} md={2} className="stack-box">
                    <img src={stackdb2} />
                  </Col>
                  <Col xs={3} sm={2} md={2} className="stack-box">
                    <img src={stackdb3} />
                  </Col>
                  <Col xs={3} sm={2} md={2} className="stack-box">
                    <img src={stackdb4} />
                  </Col>
                  <Col xs={3} sm={2} md={2} className="stack-box">
                    <img src={stackdb5} />
                  </Col>
                </Row>
                <Row className="justify-content-center">
                  <Col xs={3} sm={2} md={2} className="stack-box">
                    <img src={stackdb6} />
                  </Col>
                  <Col xs={3} sm={2} md={2} className="stack-box">
                    <img src={stackdb7} />
                  </Col>
                  <Col xs={3} sm={2} md={2} className="stack-box">
                    <img src={stackdb8} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <div className="section-stack-box">
            <Row className="justify-content-md-center align-items-center">
              <Col xs={12} className="d-flex justify-content-center">
                {" "}
                <div className="section-stack-text-box">AI / DATA</div>
              </Col>
            </Row>
            <Row className="justify-content-md-center align-items-center">
              <Col md="auto">
                <Row className="justify-content-center">
                  <Col xs={3} sm={2} md={2} className="stack-box">
                    <img src={stackai1} />
                  </Col>
                  <Col xs={3} sm={2} md={2} className="stack-box">
                    <img src={stackai2} />
                  </Col>
                  <Col xs={3} sm={2} md={2} className="stack-box">
                    <img src={stackai3} />
                  </Col>
                  <Col xs={3} sm={2} md={2} className="stack-box">
                    <img src={stackai4} />
                  </Col>
                  <Col xs={3} sm={2} md={2} className="stack-box">
                    <img src={stackai5} />
                  </Col>
                </Row>
                <Row className="justify-content-center">
                  <Col xs={3} sm={2} md={2} className="stack-box">
                    <img src={stackai6} />
                  </Col>
                  <Col xs={3} sm={2} md={2} className="stack-box">
                    <img src={stackai7} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <div className="section-stack-box">
            <Row className="justify-content-md-center align-items-center">
              <Col xs={12} className="d-flex justify-content-center">
                {" "}
                <div className="section-stack-text-box">DEV TOOL</div>
              </Col>
            </Row>
            <Row className="justify-content-md-center align-items-center">
              <Col md="auto">
                <Row className="justify-content-center">
                  <Col xs={3} sm={3} md={3} className="stack-box">
                    <img src={stacktool1} />
                  </Col>
                  <Col xs={3} sm={3} md={3} className="stack-box">
                    <img src={stacktool2} />
                  </Col>
                  <Col xs={3} sm={3} md={3} className="stack-box">
                    <img src={stacktool3} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <div className="section-stack-box">
            <Row className="justify-content-md-center align-items-center">
              <Col xs={12} className="d-flex justify-content-center">
                {" "}
                <div className="section-stack-text-box">DESIGN</div>
              </Col>
            </Row>
            <Row className="justify-content-md-center align-items-center">
              <Col md="auto">
                <Row className="justify-content-center">
                  <Col xs={3} sm={2} md={2} className="stack-box">
                    <img src={stackdesign1} />
                  </Col>
                  <Col xs={3} sm={2} md={2} className="stack-box">
                    <img src={stackdesign2} />
                  </Col>
                  <Col xs={3} sm={2} md={2} className="stack-box">
                    <img src={stackdesign3} />
                  </Col>
                  <Col xs={3} sm={2} md={2} className="stack-box">
                    <img src={stackdesign4} />
                  </Col>
                  <Col xs={3} sm={2} md={2} className="stack-box">
                    <img src={stackdesign5} />
                  </Col>
                </Row>
                <Row className="justify-content-center">
                  <Col xs={3} sm={2} md={2} className="stack-box">
                    <img src={stackdesign6} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
      {/* SECTION 5 */}
      <section id="portfolio" className="text-center grey section-portfolio">
        <Container>
          <div className="py-4">
            <h2 className="cst-title-2 bold">테크프리 포트폴리오</h2>
          </div>
          <Row className="justify-content-md-center">
            {sortedItems.map((item) => (
              <Col
                key={item.id}
                md={4}
                className="mb-4 d-flex align-items-center justify-content-center"
              >
                <button
                  className="justify-content-center align-items-center d-flex flex-column portfolio-box"
                  onClick={() => openModal(item)}
                >
                  <div className="portfolio-thumbnail">
                    <img src={item.images[0]} alt={item.title} />
                  </div>
                  <h4>{item.title}</h4>
                  <div className="d-flex gap-1">
                    {item.tags.map((tag, index) => (
                      <div key={index} className="cst-box-tag">
                        {tag}
                      </div>
                    ))}
                  </div>
                </button>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
      {/* SECTION 6 */}
      <section className="text-center pt-4 section-contact">
        <Container>
          <div className="section-contact-box">
            <h1>무엇이 필요하신가요?</h1>
            <p>친절하고 정확한 답변과 견적을 제시해드립니다</p>
            <Link to="/contact">
              <button>견적 확인하기</button>
            </Link>
          </div>
        </Container>
      </section>
    </>
  );
};

export default HomePage;
