import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Comp_Nav from "./components/Nav";
import Comp_Footer from "./components/Footer";

import HomePage from "./pages/HomePage";
import ContactPage from "./pages/ContactPage";
import PortfolioPage from "./pages/PortfolioPage";
import ProjectDetailPage from "./pages/ProjectDetailPage";

import "./styles/style.css";

const App = () => {
  // 마우스커서효과
  const [cursorPos, setCursorPos] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const moveCursor = (e) => {
      setCursorPos({ x: e.clientX, y: e.clientY });
    };

    document.addEventListener("mousemove", moveCursor);

    return () => {
      document.removeEventListener("mousemove", moveCursor);
    };
  }, []);

  return (
    <>
      <div
        className="custom-cursor"
        style={{
          left: `${cursorPos.x}px`,
          top: `${cursorPos.y}px`,
        }}
      />
      <Router>
        <Comp_Nav />
        <div style={{ paddingTop: "56px" }}>
          <Routes>
            {" "}
            <Route path="/" element={<HomePage />} />{" "}
            <Route path="/contact" element={<ContactPage />} />{" "}
            <Route path="/web" element={<PortfolioPage categoryName="web" />} />
            <Route
              path="/mobile"
              element={<PortfolioPage categoryName="mobile" />}
            />
            <Route
              path="/platform"
              element={<PortfolioPage categoryName="platform" />}
            />
            <Route
              path="/software"
              element={<PortfolioPage categoryName="software" />}
            />
            <Route
              path="/research"
              element={<PortfolioPage categoryName="research" />}
            />
            <Route path="/:category/:id" element={<ProjectDetailPage />} />
          </Routes>
        </div>
        <Comp_Footer />
      </Router>
    </>
  );
};

export default App;
