import React from "react";
import { Container, Row, Col, Navbar } from "react-bootstrap";
import logo from "../assets/textlogo_wh.svg"; // 로고 이미지 경로

const Comp_Footer = () => {
  return (
    <Navbar className="cst-back-dark">
      <Container>
        {/* Combined Row for Logo, Company Info, and Customer Service */}
        <Row className="align-items-start justify-content-between footer-box">
          {/* 로고 섹션 */}
          <Col xs={12} md={12} className="text-md-left footer-logo pb-4">
            <img src={logo} height="30" alt="Logo" />
          </Col>

          {/* 회사 정보 섹션 */}
          <Col xs={12} md={6} className="footer-text-box">
            <h5>회사정보</h5>
            <p>
              주소: 서울특별시 서초구 사임당로8길 13, 4층 402호 에이514(서초동,
              제일 빌딩)
            </p>
            <p>사업자등록번호: 779-88-02908</p>
          </Col>

          {/* 고객센터 섹션 */}
          <Col xs={12} md={6} className="footer-text-box">
            <h5>고객센터</h5>
            <p>이메일: adamkim@techfree.kr</p>
            <p>전화: 010-8940-6634</p>
          </Col>
        </Row>
      </Container>
    </Navbar>
  );
};

export default Comp_Footer;
